
import { defineComponent, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbsWithParams } from "@/core/helpers/breadcrumb";
import PolicyForm from "@/views/policy/PolicyForm.vue";
export default defineComponent({
  name: "Create",
  components: {PolicyForm},
  setup() {
    const store = useStore();
    const model = reactive({
      policy: {}
    })
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Add Policy', [
        {link: true, router: '/policies', text: 'Search Policy'},
        {link: false, router: '', text: 'Add Policy'}
      ])
    })
    return {
      store,
      model
    }
  }
})
